import { Container, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import useResponsive from '../hooks/useResponsive';
import NewLogin from './NewLogin';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'grid',
  placeItems: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
}));

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  padding: theme.spacing(12, 0),
}));

export default function AdminLoginPage() {
  const loginData = useSelector((pre) => pre.GetVendorLoginReducer);
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Admin Login </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ position: 'absolute', top: '15%' }}>
              Welcome To Hotelio, Your Travel Partner
            </Typography>
            <img src="/assets/illustrations/illustration_login.svg" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
          <RingLoader
            color="#ff543b"
            loading={loginData.isLoading}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50% , -50%)',
            }}
          />
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in to Hotelio 🏨
            </Typography>

            <NewLogin isRole="admin" />

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider>
            <Typography variant="p" sx={{ mt: 5, mr: 1 }}>
              New on our platform?
            </Typography>
            <Link to="/register">Create an account</Link>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
